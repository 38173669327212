<template>
  <div>
    <div class="--m-lg">
      <a class="--clickable" v-on:click="$emit('nav-to', '/')">← Home</a>
    </div>
    <img
      class="__single-screen --rounded-corners"
      src="../assets/images/turtle-daycare/tdc-logo.png"
      alt="Turtle Daycare Logo"
    />

    <p>
      You're caretaker of a group of turtles as they go about their day. Feed
      them, wash them, and get them to bed on time. At best it's your own
      personal zen garden with some playful inhabitants. At worst it's like
      watching 10 slow-motion disasters happening all at once. A lot of joy,
      silliness & frustration.
    </p>
    <p>
      The project is now in early testing.
      <a href="https://maxjaksa.github.io/Bubbles/"
        >Try it in your browser here→
      </a>
    </p>
    <div class="__divider" />
    <h4>Media</h4>
    <div>
      <h5>Gameplay Trailer</h5>
      <div class="__embedded-video-container">
        <iframe
          src="https://player.vimeo.com/video/943266615?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          title="Turtle Daycare - Gameplay Trailer"
        ></iframe>
      </div>
      <h5>Screens</h5>
      <img
        class="__single-screen --rounded-corners"
        src="../assets/images/turtle-daycare/tdc-screenshot-0.png"
        alt="Turtle Daycare - game screenshot"
      />
      <img
        class="__single-screen --rounded-corners"
        src="../assets/images/turtle-daycare/tdc-screenshot-3.png"
        alt="Turtle Daycare - game screenshot"
      />
      <img
        class="__single-screen --rounded-corners"
        src="../assets/images/turtle-daycare/tdc-screenshot-1.png"
        alt="Turtle Daycare - game screenshot"
      />
      <img
        class="__single-screen --rounded-corners"
        src="../assets/images/turtle-daycare/tdc-screenshot-2.png"
        alt="Turtle Daycare - game screenshot"
      />
      <h5>Some early development fun</h5>
      <p>
        I spent a lot of trial & error trying to get walking to feel good in
        such a way that would be both performant and easily modifiable on a per
        turtle basis. I started thinking that it could be a mix of procedural
        and keyframed animation and ended up going fully procedural.
      </p>
      <div class="__gameplay-screen-rows">
        <div class="__gameplay-screen-row">
          <img
            class="__gameplay-screen"
            src="../assets/images/turtle-daycare/tdc-vision-and-walking.gif"
            alt="Turtle Daycare - Vision and Walking"
          />
          <img
            class="__gameplay-screen"
            src="../assets/images/turtle-daycare/tdc-box-turtle.gif"
            alt="Turtle Daycare - Box Turtle"
          />
        </div>
        <p>
          More time and attention went into pooping than I care to admit.
          <b>Side note:</b> it feels strange to use the word "poop" within a
          codebase as much as I do here. Games right?
        </p>
        <div class="__gameplay-screen-row">
          <img
            class="__gameplay-screen"
            src="../assets/images/turtle-daycare/tdc-walk-and-poop.gif"
            alt="Turtle Daycare - Walk and Poop"
          />
          <img
            class="__gameplay-screen"
            src="../assets/images/turtle-daycare/tdc-poop-mountain.gif"
            alt="Turtle Daycare - Poop Mountain"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
img {
  margin-bottom: 20px;
}
.__gameplay-screen-rows {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.__gameplay-screen-row {
  position: relative;
  display: flex;
  gap: 4px;
}
.__gameplay-screen {
  width: calc(50% - 2px);
}
.__embedded-video-container {
  padding: 56.25% 0 0 0;
  position: relative;
}
</style>
