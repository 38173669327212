<template>
  <div>
    <div class="--m-lg">
      <a class="--clickable" v-on:click="$emit('nav-to', '/')">← Home</a>
    </div>
    <img
      class="__single-screen --rounded-corners"
      src="../assets/images/space-rangers-go/srg-logo.png"
      alt="Space Rangers Go - logo"
    />
    <p>
      Race to the finish, or just tag around with up to three of your friends,
      <b>Space Rangers Go</b> is an experiment in emergent gameplay. What
      happens when a few friends try to co-navigate a procedurally-generated
      maze with no rules, no time limit, and some pretty flimsy narrative
      context?
      <a class="--inline" href="https://spacerangersgo.biz/"
        >Give it a try and find out →
      </a>
    </p>
    <p>
      This project was inspired by my kids and some of the games that they've
      conjured over the years. They're both heavily invested in solving mazes
      and endlessly chase each other around the house -- what's the videogame
      version of that?
    </p>
    <p>
      My aim was to deliver the simplest experience possible -- from the the
      overall look & feel, to the player interactions, to the technology
      underpinning it all. Visually, this led me down a path of minimalistic
      colored boxes and monochrome character sprites. Technologically, I skipped
      preexisting game engines and graphics libraries in favor of a
      stripped-down <b>HTML/Canvas/JavaScript</b> client and a basic
      <b>NodeJS</b> server (websockets!).
    </p>
    <div class="__divider" />
    <h4>Look & Feel</h4>
    <div>
      <p>
        My aim was to create something that felt economical, but not necessarily
        lo-fi or deliberately pixelated.
      </p>
      <p>
        I really enjoy some of the very simple, blocky mazes in early games
        (looking at you,
        <b>Adventure</b>) — I like to think of the hard blocks and square edges
        as deliberate design decisions and not choices forced by limitations in
        technology.
      </p>
      <h5>Gameplay Screens</h5>
      <img
        class="__single-screen"
        src="../assets/images/space-rangers-go/srg-screenshot-1.png"
        alt="Space Rangers Go - game screenshot"
      />
      <img
        class="__single-screen"
        src="../assets/images/space-rangers-go/srg-screenshot-3.png"
        alt="Space Rangers Go - game screenshot"
      />
      <img
        class="__single-screen"
        src="../assets/images/space-rangers-go/srg-screenshot-2.png"
        alt="Space Rangers Go - game screenshot"
      />
      <h5>Gifs!</h5>
      <div class="__gameplay-screen-rows">
        <div class="__gameplay-screen-row">
          <img
            class="__gameplay-screen"
            src="../assets/images/space-rangers-go/space-rangers-binoculars.gif"
            alt="Space Rangers Go - idle"
          />
          <img
            class="__gameplay-screen"
            src="../assets/images/space-rangers-go/space-rangers-boots.gif"
            alt="Space Rangers Go - idle"
          />
        </div>
        <div class="__gameplay-screen-row">
          <img
            class="__gameplay-screen"
            src="../assets/images/space-rangers-go/space-rangers-zap.gif"
            alt="Space Rangers Go - idle"
          />
          <img
            class="__gameplay-screen"
            src="../assets/images/space-rangers-go/space-rangers-exit.gif"
            alt="Space Rangers Go - idle"
          />
        </div>
      </div>
      <h5>Dashboard & UI</h5>
      <p>
        The overall screen layout & UI was shaped by my affinity for interfaces
        in old science fiction — everything is its own panel, no visual
        hierarchy to speak of, and everything treated with the same, unforgiving
        high-contrast (sorry, human retinas).
      </p>
      <img
        class="__single-screen"
        src="../assets/images/space-rangers-go/srg-dashboard.png"
        alt="Space Rangers Go - game dashboard"
      />
      <h5>Early Concepting</h5>
      <p>
        A close-to-final version of my astronauts. This version had eyeballs
        that made for fun animations. While I liked this design, I felt like it
        added too much visual complexity to the game and became a distraction.
      </p>
      <div>
        <img
          src="../assets/images/space-rangers-go/space-rangers.gif"
          alt="Space Rangers Go - character animations"
        />
      </div>
      <p>
        It was important the characters fit well into a square collider box. I
        was at a loss until I added a jetpack which really squared them out.
        Some early sketches:
      </p>
      <img
        class="__single-screen --rounded-corners"
        src="../assets/images/space-rangers-go/space-rangers-sketches.png"
        alt="Space Rangers Go - early character concepts"
      />
    </div>
    <div class="__divider" />
    <h4>Map Generation</h4>
    <p>
      I began with the intent of using only fully procedurally generated maps.
      This took me down a deep rabbit hole that involved lots of trial and error
      and restarts. At a certain point, it became evident that I could easily
      spend months and months refining this process without much payoff.
    </p>
    <p>
      Instead, I leverage procedural generation for <b>~80%</b> of each map.
      When a generated map seemed promising, I'd then jump in and hand-tune it
      to become fully balanced and playable.
    </p>
    <h5>Chunks</h5>
    <p>
      Map generation begins with a series of predefined map "chunks" (this was
      inspired by an article I read about <b>Spelunky</b> forever ago). I wrote
      my editor in <b>Vue.js + Electron</b> to help visualize this process.
      Using the editor, I can author as many map chunks as I like.
    </p>
    <img
      class="__single-screen --rounded-corners-small --outline"
      src="../assets/images/space-rangers-go/minos-chunk-generation.gif"
      alt="Space Rangers Go Logo"
    />
    <h5>Chunk Placement</h5>
    <p>
      I then randomly plot the predefined map chunks along randomly generated
      paths across a 2D grid. The result is something vaguely maze-like.
    </p>
    <img
      class="__single-screen --rounded-corners-small --outline"
      src="../assets/images/space-rangers-go/minos-map-generation.gif"
      alt="Space Rangers Go Logo"
    />
    <h5>Traversability</h5>
    <p>
      In order for two map chunks to be placed side-by-side, I need to ensure
      that the two chunks are traversable for our game characters (walkable or
      within a character's jump range); otherwise the map doesn't work. This
      lead to a lot more code than I was expecting :(
    </p>
    <img
      class="__single-screen --rounded-corners-small --outline"
      src="../assets/images/space-rangers-go/minos-board-layers.gif"
      alt="Space Rangers Go Logo"
    />
    <h5>Primary & Alternate Paths</h5>
    <p>
      For each map to be a maze, it needs a single primary path (the correct
      solution) that links a spawn point with the exit -- but it must also
      contain multiple forks and incorrect paths. A pathing quick visualization:
    </p>
    <img
      class="__single-screen --rounded-corners-small --outline"
      src="../assets/images/space-rangers-go/minos-board-paths.gif"
      alt="Space Rangers Go Logo"
    />
    <h5>Learnings</h5>
    <p>
      It was really hard to step away from map generation. At the end of the day
      however, I really didn't see procedural map generation adding to the fun
      of the game (I'd much rather have 10 good maps than 100 mediocre ones). It
      ended up becoming a nice jumping off point for creating maps but not much
      else.
    </p>
    <p>
      In the future I'd like to be more diligent about making key decisions that
      are clearly aligned with the goals of the project. If
      <b>Jurassic Park</b> taught me anything, it's that just because
      <i>we can</i> doesn't mean <i>we should</i>.
    </p>
  </div>
</template>

<style lang="scss" scoped>
.__gameplay-screen-rows {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.__gameplay-screen-row {
  position: relative;
  display: flex;
  gap: 4px;
}
.__gameplay-screen {
  width: calc(50% - 2px);
}
</style>
